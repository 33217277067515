import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useHelpers } from "../../../Utils/helpers";
import { useInternalActionCreator } from "../../Actions/InternalActionCreator";
import { modalTypes } from "../../Constants/modalTypes";
import { CreateNewMigrationModal } from "./CreateNewMigrationModal";
import { MigrationType } from "../Enums/CommonEnums";
import Checkbox from "../Helpers/Checkbox";
import { onToggleModal } from "../../Reducers/modalReducer";
import LoadingIcon from "../Icons/LoadingIcon";
import ListSearch from "../Helpers/ListSearch";
import { clearInternalMigrations, storeVonCustomer, VonCustomer } from "../../Reducers/internalMigrationReducer";
import { setSortChange } from "../../Reducers/paginationReducer";
import { setSearchResultsCount } from "../../Reducers/searchReducer";
import { routes } from "../../Constants/routes";
import { CommentsModal } from "./CommentsModal";
import { paginationProps } from "./Interfaces";

export default function InternalListV2() {
    const internalActions = useInternalActionCreator();
    const migrationList = useAppSelector((state) => state.internalMigration.internalMigrationList);
    const migrationCount = useAppSelector((state) => state.internalMigration.migrationCount);
    const navigate = useNavigate();
    const helpers = useHelpers();
    const totalPages = useAppSelector((state) => state.internalMigration.totalPages);
    const activeModal = useAppSelector((state) => state.modal.modal);
    const dispatch = useAppDispatch();

    const [savedSort, setSavedSort] = useState({ sortOrder: "DESC", key: "Changed" });
    const [selectedMigrations, setSelectedMigrations] = useState<string[]>([]);
    const [selectedMigrationId, setMigrationId] = useState<string>("");
    const [sortOrderForIcon, setSortOrderForIcon] = useState(<i></i>);
    const [filteredMigrations, setFilteredMigrations] = useState(migrationList);
    const [inputValue, setInputValue] = useState<string>("");

    const storageKey = "internalListState";

    const storage = {
        getItem: () => {
            const item = sessionStorage.getItem(storageKey);
            return item ? JSON.parse(item) : {};
        },
        setItem: (state: any) => {
            sessionStorage.setItem(storageKey, JSON.stringify(state));
        }
    };

    const initialState = storage.getItem();
    const showComponent = initialState.showComponent || "new";
    const setShowComponent = (comp: string) => {
        const state = storage.getItem();
        state.showComponent = comp;
        storage.setItem(state);
    };

    const storedOffset = initialState.storedOffset || 0;
    const setStoredOffset = (offset: number) => {
        const state = storage.getItem();
        state.storedOffset = offset;
        storage.setItem(state);
    };

    const storedPageNumber = initialState.storedPageNumber || 1;
    const setStoredPageNumber = (pageNumber: number) => {
        const state = storage.getItem();
        state.storedPageNumber = pageNumber;
        storage.setItem(state);
    };

    const sortOrder = initialState.sortOrder || { key: "Changed", sortOrder: "DESC" };
    const setSortOrder = (sort: { key: string, sortOrder: string }) => {
        const state = storage.getItem();
        state.sortOrder = sort;
        storage.setItem(state);
    };

    const [currentPage, setCurrentPage] = useState(storedPageNumber);

    const [paginationProps, setPaginationProps] = useState<paginationProps>({
        status: showComponent === null ? "new" : showComponent,
        sortColumn: savedSort.key,
        sortOrder: savedSort.sortOrder,
        searchPhrase: "",
        offset: storedOffset ? parseInt(storedOffset, 10) : 0,
        rowsPerPage: 25
    })

    useEffect(() => {
        internalActions.getPaginatedMigrationsInternal(paginationProps);
    }, [paginationProps]);

    useEffect(() => {
        setFilteredMigrations(migrationList);
    }, [migrationList]);

    useEffect(() => {
        const state = storage.getItem();
        if (state.sortOrder) {
            setSavedSort(state.sortOrder);
            setPaginationProps((prevProps) => ({
                ...prevProps,
                sortColumn: state.sortOrder.key,
                sortOrder: state.sortOrder.sortOrder,
            }));
        }
    }, [showComponent]);

    function changeTab(status: string) {
        savePageNumber(1);
        setPaginationProps({ ...paginationProps, status: status, offset: 0 });
        setSelectedMigrations([]);
        setShowComponent(status);
    }

    const onSelectMigration = (id: string) => {
        const isSelected = selectedMigrations.includes(id);

        if (isSelected)
            setSelectedMigrations(prevSelected => prevSelected.filter(item => item !== id));
        else
            setSelectedMigrations(prevSelected => [...prevSelected, id]);
    };

    function onDelete() {
        internalActions.deleteMigrations(selectedMigrations, () => {
            sortOnColumns(savedSort.key ? savedSort.key : "Changed")
            setSelectedMigrations([])
        })
    }

    function onNavigate(id: string) {
        navigate(routes.internalMigrationDetails(id));
    }

    function getSortTypeForIcon(sort: any) {
        const order = sort.sortOrder === "ASC" ? <i className="pull-right vismaicon vismaicon-dynamic vismaicon-sm vismaicon-move-up-circle"></i> : <i className="pull-right vismaicon vismaicon-dynamic vismaicon-sm vismaicon-move-down-circle"></i>;
        setSortOrderForIcon(order);
    }

    function sortOnColumns(key: string) {
        dispatch(setSortChange(true));
        dispatch(clearInternalMigrations());
        const sort = sortOrder || { key: "Changed", sortOrder: "DESC" };
        if (key !== sort.key) {
            sort.key = key;
            sort.sortOrder = key === "Changed" ? "DESC" : "ASC";
        } else {
            sort.sortOrder = sort.sortOrder === "ASC" ? "DESC" : "ASC";
        }
        setSortOrder(sort);
        setSavedSort(sort);
        getSortTypeForIcon(sort);

        setPaginationProps({ ...paginationProps, sortColumn: sort.key, sortOrder: sort.sortOrder, offset: 0 });
    }

    function showComments(id: string) {
        setMigrationId(id);
        dispatch(onToggleModal(modalTypes.commentsModal));
    }

    function checkStatus(status: number) {
        if (status <= 4)
            return <i className="vismaicon vismaicon-dynamic vismaicon-edit disabled"></i>;
        else if (status > 4 && status < 190 && status !== 9)
            return <LoadingIcon></LoadingIcon>;
        else if (status >= 190 && status <= 250)
            return <i className="vismaicon vismaicon-filled vismaicon-success"></i>;
        else
            return <i className="vismaicon vismaicon-filled vismaicon-error"></i>;
    }

    function savePageNumber(pageNumber: number) {
        setCurrentPage(pageNumber);
        setStoredPageNumber(pageNumber);
    }

    function goToPage(pageNumber: number) {
        const newOffset = (pageNumber - 1) * paginationProps.rowsPerPage;
        savePageNumber(pageNumber);
        setStoredOffset(newOffset);
        setPaginationProps({ ...paginationProps, offset: newOffset });
    }
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handlePageJump = () => {
        const pageNumber = Number(inputValue);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            goToPage(pageNumber);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handlePageJump();
        }
    };

    const goToFirstPage = () => {
        goToPage(1);
    };

    const goToLastPage = () => {
        goToPage(totalPages);
    };

    function createNewManualMigration() {
        dispatch((storeVonCustomer({} as VonCustomer)))
        dispatch(onToggleModal(modalTypes.createNewMigrationModal))
    }

    function handleSearch(searchTerm: string) {
        const filtered = migrationList.filter((migration) =>
            migration.customerName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredMigrations(filtered);
        dispatch(setSearchResultsCount(filtered.length));
    }

    function onCheckAllCollaborations() {
        internalActions.checkAllWaitingCollaborations();
        dispatch(clearInternalMigrations());
    }

    function renderButtonGroup() {
        const statuses = [
            { label: "Nya", status: "new" },
            { label: "Väntande samarbeten", status: "collaborations" },
            { label: "Pågående", status: "ongoing" },
            { label: "Manuellt", status: "manual" },
            { label: "Klart", status: "success" },
            { label: "Misslyckat", status: "error" },
            { label: "Acceptera avtal", status: "accept" },
            { label: "Slutfört", status: "complete" },
        ];

        return (
            <div className="d-flex justify-content-center align-items-center mt-12">
                <div className=" d-flex justify-content-center">
                    <div className="btn-group btn-group-tabs internal-filters pt-0">
                        {statuses.map(({ label, status }) => (
                            <button className={`btn ${status === paginationProps.status ? "active" : ""}`} key={status} onClick={() => changeTab(status)}>
                                {label} <span className="badge">{helpers.getStatusForBadge(status, migrationCount)}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    function renderSearchAndDeleteButtons() {
        return (
            <div className="d-flex justify-content-between align-items-center pb-0">
                <div className="pb-0">
                    <ListSearch placeholder={"Sök på migreringar..."} id={"migrations"} onSearch={handleSearch} />
                </div>
                <div>
                    {paginationProps.status === "collaborations" && migrationList.length > 0 && (
                        <button className="btn btn-primary mr-12" onClick={() => onCheckAllCollaborations()}>
                            Kontrollera samarbeten
                        </button>
                    )}
                    <button onClick={createNewManualMigration} className="btn btn-primary mr-12">Skapa ny migrering</button>
                    <button className="btn btn-primary btn-destructive" disabled={selectedMigrations.length <= 0} onClick={() => onDelete()}>
                        <i className="vismaicon vismaicon-sm vismaicon-delete"></i>Ta bort
                    </button>
                </div>
            </div>
        );
    }
    function renderMigrationTable() {
        return (
            <>      
                <div className="mt-16 panel">
                    <table className="table table-stripe table-regular">
                        <thead>
                            <tr>
                                <th></th>
                                <th style={{ width: "5rem" }}>Guide</th>
                                <th style={{ width: "5rem" }}>Detaljer</th>
                                <th className="cursorpointer" onClick={() => sortOnColumns('AoCustomerName')}>Byrå{savedSort.key === 'AoCustomerName' && sortOrderForIcon}</th>
                                <th className="cursorpointer" onClick={() => sortOnColumns('OrganizationNumber')}>Orgnummer{savedSort.key === 'OrganizationNumber' && sortOrderForIcon}</th>
                                <th className="cursorpointer" onClick={() => sortOnColumns('CustomerName')}>Namn{savedSort.key === 'CustomerName' && sortOrderForIcon}</th>
                                <th>Typ</th>
                                <th>Status</th>
                                <th>Källa</th>
                                <th className="cursorpointer" onClick={() => sortOnColumns('Changed')}>Senast ändrad{savedSort.key === 'Changed' && sortOrderForIcon}</th>
                                <th>Tilldelad till</th>
                                <th>Kommentar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredMigrations.length === 0 ? (
                                <tr>
                                    <td colSpan={12} className="text-center">Ingen data finns tillgänglig</td>
                                </tr>
                            ) : (
                                filteredMigrations.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">
                                                <Checkbox value={selectedMigrations.includes(item.id)}
                                                    customClass={"mb-0"} id={item.id}
                                                    onChange={() => onSelectMigration(item.id)} />
                                            </td>
                                            <td className="text-center">
                                                <i className={item.guideCompleted ? "vismaicon vismaicon-filled vismaicon-success" : "vismaicon vismaicon-ok-circle disabled"}></i>
                                            </td>
                                            <td className="text-center">
                                                <span className="cursorpointer" onClick={() => onNavigate(item.id)}>
                                                    {checkStatus(item.status)}
                                                </span>
                                            </td>
                                            <td>{item.aoCustomerName}</td>
                                            <td className="text-nowrap">{item.organizationNumber}</td>
                                            <td>{item.customerName}</td>
                                            <td>{MigrationType[item.type]}</td>
                                            <td>{helpers.getStatusName(item.status)}</td>
                                            <td>{helpers.getSourceType(item.sourceType)}</td>
                                            <td className="text-nowrap">
                                                {helpers.formatDateAndTime(item.changed, false)}
                                            </td>
                                            <td>{item.assignedTo?.name}</td>
                                            <td>
                                                <button type="button" className="btn btn-sm btn-default" onClick={() => showComments(item.id)}>Kommentarer {item.comments > 0 && <span className="badge">{item.comments}</span>}</button>
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    function renderPageNumbers() {
        return (
            <div>
                Sida {currentPage} av {totalPages}
            </div>
        );
    }


    function nextPage() {
        if (currentPage < totalPages) {
            const newPageNumber = currentPage + 1;
            const newOffset = (newPageNumber - 1) * paginationProps.rowsPerPage;
            setPaginationProps({ ...paginationProps, offset: newOffset });
            setStoredOffset(newOffset);
            savePageNumber(newPageNumber);
        }
    }

    function prevPage() {
        if (currentPage > 1) {
            const newPageNumber = currentPage - 1;
            const newOffset = (newPageNumber - 1) * paginationProps.rowsPerPage;
            savePageNumber(newPageNumber);
            setStoredOffset(newOffset);
            setPaginationProps({ ...paginationProps, offset: newOffset });
        }
    }

    function changeRowPerPage(value: number) {
        setPaginationProps({ ...paginationProps, rowsPerPage: value, offset: 0 });
        setStoredOffset(0);
        savePageNumber(1);
    }

    function showNumberOfRows() {
        const rowOptions = [25, 50, 75, 100];
        return (
            <div className="d-flex justify-content-center pt-12">
                <div className="d-flex flex-column">
                    <div className="text-center">
                        <span>Visa antal rader per sida:</span>
                    </div>
                    <div className="btn-group btn-group-tabs btn-group-tabs-sm mr-16" role="group" aria-label="Segmented controls">
                        {rowOptions.map((option) => (
                            <button
                                type="button"
                                key={option}
                                className={`btn ${paginationProps.rowsPerPage === option ? 'active' : ''}`}
                                aria-selected={paginationProps.rowsPerPage === option}
                                onClick={() => changeRowPerPage(option)}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    function renderPaginationButtons() {
        return (
            <div className="d-flex justify-content-center flex-column">
                <div className="d-flex justify-content-center align-items-center">
                    <ul className="pagination d-flex align-items-center justify-content-center">
                        <li>
                            <button className="go2first btn" onClick={goToFirstPage} disabled={currentPage === 1}>First</button>
                        </li>
                        <li>
                            <button className="prev btn" onClick={prevPage} disabled={currentPage === 1}></button>
                        </li>
                        <span className="pt-12 pb-12 pl-12 pr-12">
                            {renderPageNumbers()}
                        </span>
                        <li>
                            <button className="next btn" onClick={nextPage} disabled={currentPage >= totalPages}></button>
                        </li>
                        <li>
                            <button className="go2last btn" onClick={goToLastPage} disabled={currentPage >= totalPages}></button>
                        </li>
                    </ul>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-6">
                    <label className="ml-8" htmlFor="jumpToPage">Gå till sida:</label>
                    <input
                        type="number"
                        id="jumpToPage"
                        name="pageJump"
                        min="1"
                        max={totalPages}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handlePageJump}
                        className="pl-12 ml-12 pr-12"
                    />
                </div>
                <div>
                    {showNumberOfRows()}
                </div>
            </div>
        );
    }


    return (
        <>
            {activeModal === modalTypes.commentsModal && <CommentsModal migrationId={selectedMigrationId} />}
            {activeModal === modalTypes.createNewMigrationModal && <CreateNewMigrationModal />}
            <div className="migration-list container-padding">
                {renderSearchAndDeleteButtons()}
                {renderButtonGroup()}
                {renderMigrationTable()}
                {renderPaginationButtons()}
            </div>
        </>
    );
}