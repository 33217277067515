import React, {useEffect, useRef, useState} from "react";
import { UserModel } from "../../Reducers/vonReducer";

export default function PhoneFilterDropdown(props: any) {
    const ref = useRef(null);
    const [showFilter, setShowFilter] = useState(false);
    const [showAssignedFilter, setShowAssignedFilter] = useState(false);
    const [showStartedFilter, setShowStartedFilter] = useState(false);

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    function handleClick(e: any) {
        const current = ref.current as Node | null
        if (current && !current.contains(e.target)) {
            setShowFilter(false);
            setShowStartedFilter(false);
            setShowAssignedFilter(false);
        }
    }

    return <div ref={ref} className={`dropdown user-dropdown ${showFilter ? "open" : ""}`}>
        <i className="vismaicon vismaicon-dynamic vismaicon-filter"
           aria-expanded={showFilter} onClick={() => setShowFilter(!showFilter)}
        ></i>
        <ul className="dropdown-menu phone-filter-dropdown" aria-expanded={showFilter}
            aria-hidden={!showFilter}>
            <li className="dropdown-item" onClick={() => props.filterMigrations("all")}>
                <a>Alla</a>
            </li>
            <li>
                <a onClick={() => setShowStartedFilter(!showStartedFilter)}>Startade av</a>
            </li>
            {/*<li>*/}
            {/*    <a onClick={() => setShowAssignedFilter(!showAssignedFilter)}>Tilldelade till</a>*/}
            {/*    {showAssignedFilter && <ul className="dropdown-menu dropdown-in-dropdown">*/}
            {/*        {props.assignedToDropdown.map((item: any, index:number) => {*/}
            {/*            return <li key={index} onClick={() => {*/}
            {/*                props.filterMigrations("assigned", item.name);*/}
            {/*                setShowAssignedFilter(false);*/}
            {/*            }}>*/}
            {/*                {item.name}*/}
            {/*            </li>*/}
            {/*        })}*/}
            {/*    </ul>}*/}
            {/*</li>*/}

        </ul>
        {showStartedFilter && <ul className="dropdown-menu dropdown-in-dropdown">
            {props.startedByDropdown.map((item: UserModel, index: number) => {
                return <li key={index} onClick={() => {
                    props.filterMigrations("started", item.fullName);
                    setShowStartedFilter(false)
                }}>
                    {item.fullName}
                </li>
            })}
        </ul>}
    </div>;
}