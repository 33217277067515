import { useHelpers } from "../../../Utils/helpers";
import { ErrorsAndWarnings } from "../../Reducers/internalMigrationReducer";


export default function WarningLog(props: any) {
    const helpers = useHelpers();

    return (
        <div>
            <div>
                <div className="table-responsive">
                    <table className="table table-stripe">
                        <thead>
                            <tr>
                                <th>Meddelande</th>
                                <th>Datum</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.migration.warnings && props.migration.warnings.length > 0 ? props.migration.warnings.map((warning:ErrorsAndWarnings, index: number) =>
                                <tr key={index} >
                                    <td>{warning.friendlyMessage}</td>
                                    <td className="text-nowrap">{helpers.formatDateAndTime(warning.dateTime, true)}</td>
                                </tr>
                            ) : <tr><td><p>Ingen data finns tillgänglig</p></td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}