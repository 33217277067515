import React, {useState} from "react";
import {AdditionalInfo} from "../../Reducers/migrationReducer";
import TextInput from "../Helpers/TextInput";

export default function AdditionalInfoList({item, onChange, migration}: { item: AdditionalInfo, onChange: Function, migration: any }) {
    const [listItems, setListItems] = useState(item.value.split(";"));

    const changeHandler = (value: string, field: number) => {
        const newListItems = listItems.map((v, index) => index === field ? value : v);
        const filteredListItems = newListItems.filter(v => v.trim() !== "");

        setListItems(newListItems);
        onChange(filteredListItems.join(";"));
    };

    const label = (
        <span>{item.keyName || item.key}{item.required ? <span style={{color: "red"}}>*</span> : null}</span>);

    return <>
        {listItems.map((listItem, listIndex) => (
            <div key={`${listIndex}`} className="align-items-center">
                <div className="row">
                    <div className="col-lg-4">
                        <TextInput label={!listIndex && label}
                                   alignment={"align-items-end"}
                                   labelWidth={8}
                                   noAutoComplete={true}
                                   value={listItem}
                                   elementWidth={8}
                                   field={listIndex}
                                   onChange={changeHandler}
                                   disabled={migration.readonly}
                        />
                    </div>
                    {!listIndex && (<div className="col-lg-4 d-flex align-items-center">
                        {item.error}
                    </div>)}
                </div>
            </div>))}
    </>;
}