import React, { useState } from "react";
import Modal from "../Helpers/Modal";
import { SourceType } from "../Enums/CommonEnums";
import { useInternalActionCreator } from "../../Actions/InternalActionCreator";
import { useAppSelector } from "../../hooks";

export function CreateNewMigrationModal() {

    const migrationActions = useInternalActionCreator();

    const [customerNumber, setCustomerNumber] = React.useState("");
    const [sourceType, setSourceType] = React.useState(SourceType.CsvFiles);

    const vonCustomer = useAppSelector(state => state.internalMigration.vonCustomer)

    const createMigration = () => {
        migrationActions.createMigration(sourceType, customerNumber);
    };

    const onBlur = () => {
        migrationActions.searchVonCustomer(customerNumber);
    }

    const enabledButton = () : boolean => {
        return !!vonCustomer && vonCustomer.customerNumber == customerNumber;
    }

    return <Modal title="Skapa ny manuell migrering" mainButtonDisabled={!enabledButton()} mainButtonTitle={"Skapa"}
                  onSave={createMigration} closeAfterSave={true}>

        <div className="form-group">
            <label>Kundnummer</label>
            <input className="form-control" type="text" placeholder="Kundnummer" value={customerNumber} onBlur={onBlur}
                   onChange={(e) => setCustomerNumber(e.target.value)}/>
        </div>
        {vonCustomer && vonCustomer.name && <div className="alert alert-info">
            <h3>{vonCustomer.name}</h3>
            <p>Kundnummer: {vonCustomer.customerNumber}</p>
            <p>Organisationsnummer: {vonCustomer.organizationNumber}</p>
        </div>
        }
    </Modal>;
}