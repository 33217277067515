import React, { useState } from 'react';
import { useFileActionCreator } from "../../Actions/FileActionCreator";
import { useInternalActionCreator } from "../../Actions/InternalActionCreator";
import { InternalMigration } from "../../Reducers/internalMigrationReducer";
import { useHelpers } from "../../../Utils/helpers";
import { onToggleModal } from "../../Reducers/modalReducer";
import { useAppDispatch } from "../../hooks";
import { useAppSelector } from "../../hooks";
import { clear, initFile } from "../../Reducers/fileEditSlice";
import { modalTypes } from "../../Constants/modalTypes";
import { ModalAlert } from "../Helpers/Modal";

export interface UploadedFile {
    email: string;
    id: string;
    formattedFileSize: string;
    filename: string;
    startDate: Date;
    endDate: Date;
    uploaded: Date;
    isAllowedToEdit: boolean;
}

interface MigrationUploadPros {
    migration: InternalMigration;
}

interface ConfirmDeleteFileModalProps {
    migrationId: string;
    itemId: string;
    deleteFile: (migrationId: string, itemId: string) => void;
}

function ConfirmDeleteFileModal({ migrationId, itemId, deleteFile }: ConfirmDeleteFileModalProps) {
    return (
        <ModalAlert mainButtonTitle="Ta bort" mainButtonDisabled={false} title="Bekräfta radering av fil" onSave={() => deleteFile(migrationId, itemId)}>
            <p>Är du säker på att du vill radera denna filen?</p>
        </ModalAlert>
    );
}

export default function MigrationUpload(props: MigrationUploadPros) {
    const [selectedFile, setSelectedFile] = useState<{ migrationId: string, itemId: string } | null>(null);
    let randomString = Math.random().toString(36);
    const helper = useHelpers();
    const migrationActions = useInternalActionCreator();
    const fileActions = useFileActionCreator();
    const dispatch = useAppDispatch();
    const downloadFile = (item: UploadedFile) => migrationActions.downloadFile(props.migration.id, item);
    const activeModal = useAppSelector((state) => state.modal.modal);


    const downloadAndEditFile = (item: UploadedFile) => migrationActions.downloadFile(props.migration.id, item, (blob: Blob, fileName: string) => {

        const confirmDeleteFile = () => dispatch(onToggleModal(modalTypes.confirmDeleteFileInternal));


        // TODO: We have to solve reading SIE files as codepage 850 since FileReader does not support it
        // const reader = new FileReader();
        //
        // reader.readAsText(blob, "iso-nl-1");
        // readBlobAsCodepage850(blob).then((text) => {
        //
        //     dispatch(clear());
        //     dispatch(onToggleModal(modalTypes.editFile))
        //     dispatch(initFile({ content: text as string, fileName }))
        // });


        // reader.addEventListener("load", function() {
        //     dispatch(clear());
        //     dispatch(onToggleModal(modalTypes.editFile))
        //     dispatch(initFile({ content: reader.result as string, fileName }))
        // });


        blob.text().then((text) => {
            dispatch(clear());
            dispatch(onToggleModal(modalTypes.editFile))
            dispatch(initFile({ content: text, fileName }))
        });
    });

    const allowedToDownload = props.migration.isAllowedToDownloadFiles;

    const reloadFilesList = () => fileActions.getFiles(props.migration.id);

    const allowedToDelete = true;

    function showInteranlFileUploadModal() {
        dispatch(onToggleModal(modalTypes.internalFileUploadModal));
    }
    const handleDeleteClick = (migrationId: string, itemId: string) => {
        setSelectedFile({ migrationId, itemId });
        dispatch(onToggleModal(modalTypes.confirmDeleteFileInternal));
    };
    return <>
        <button onClick={reloadFilesList} className="btn btn-default">Uppdatera</button>
        <button onClick={showInteranlFileUploadModal} className="btn btn-primary">Ladda upp filer</button>

        <div className="table-responsive">
            <table className="table table-stripe">
                <thead>
                    <tr>
                        <th>Filnamn</th>
                        <th>Filstorlek</th>
                        <th>Startdatum (SIE)</th>
                        <th>Slutdatum (SIE)</th>
                        <th>Uppladdad av</th>
                        <th>Uppladdad</th>
                        <th></th>
                        {allowedToDownload && <th></th>}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.migration.files && props.migration.files.map((item: UploadedFile, index: number) =>
                        <tr key={index}>
                            <td>{item.filename}</td>
                            <td>{item.formattedFileSize}</td>
                            <td>{helper.formatDateAndTime(item.startDate, false)}</td>
                            <td>{helper.formatDateAndTime(item.endDate, false)}</td>
                            <td>{item.email}</td>
                            <td>{helper.formatDateAndTime(item.uploaded, false)}</td>
                            {allowedToDelete &&
                                <td><a onClick={() => handleDeleteClick(props.migration.id, item.id)}><i
                                    className="vismaicon vismaicon-delete"></i></a></td>}
                            {allowedToDownload && <td><a onClick={() => downloadFile(item)}><i
                                className="vismaicon vismaicon-download"></i></a></td>}
                            <td>
                                {item.isAllowedToEdit && <a onClick={() => downloadAndEditFile(item)}><i
                                    className="vismaicon vismaicon-edit"></i></a>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {activeModal === modalTypes.confirmDeleteFileInternal && selectedFile &&
                <ConfirmDeleteFileModal
                    migrationId={selectedFile.migrationId}
                    itemId={selectedFile.itemId}
                    deleteFile={fileActions.deleteFile}
                />}
        </div>
    </>;
}
