import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import LeftArrowIcon from "../../Icons/LeftArrowIcon";
import { useMigrationActionCreator } from "../../../Actions/MigrationActionCreator";
import WarningLog from "../../SharedComponents/WarningLog";
import { useUrlHelper } from "../../../Constants/urlHelper";
import { routes } from "../../../Constants/routes";
import { SourceType } from "../../Enums/CommonEnums";
import Wootric from "../../Wootric/Wootric";

export default function SMBDetailedStatus() {
    const navigate = useNavigate();
    const urlHelper = useUrlHelper();
    const migrationActions = useMigrationActionCreator();
    const migration = useAppSelector(state => state.migration).migrationDetails

    const { sourceType } = useParams();
    const user = useAppSelector(state => state.users.currentUser);
    const timestamp = Math.floor(Date.now() / 1000);

    useEffect(() => {

        if(sourceType) {
            migrationActions.getSmbMigrationDetails(Number(sourceType) as SourceType)
        }
    }, [])

    function saveLogFile(mig: any) {
        migrationActions.getLogFile(mig);
    }

    if (!migration)
        return null;

    return <>
        <div className="d-flex cursorpointer" onClick={() => navigate(routes.summarizedList)}>
            <LeftArrowIcon /><h3 className="font-weight-bold">Tillbaka</h3>
        </div>
        <div className="panel">
            <div className="container-padding">
                <div className="d-flex justify-content-between">
                    <h3 className="pl-4 pt-4 font-family-ubuntu-semibold">Slutförd överföring</h3>
                </div>
                <div>
                    <div>
                        <div className="mb-32 mt-16" style={{ width: "50%" }}>
                            <h2>Nu är flytten klar och du är välkommen in i Visma eEkonomi!</h2>
                            <p>Vi vill att du går igenom ditt företag och ser till att du är nöjd med flytten, innan du börjar arbeta i ditt nya program.
                                Här nedanför får du information om vad som har hänt i samband med flytten och du kan enkelt spara denna logg för att titta på den i lugn och ro.
                                Till din hjälp har du även vårt hjälpavsnitt: <a href="https://www.vismaspcs.se/visma-support/visma-administration-2000/content/online-help/eekonomi-for-over-uppgifter-foretag.htm#efter" target="_blank">Checklista efter flytt till Visma eEkonomi »</a></p>
                            <p>Hoppas du ska trivas i ditt nya program!</p>
                            <p>Saknar du något eller har frågor om din flytt? - <a href="https://vismaspcs.se/support/visma-eekonomi#hybrid" target="_blank">Kontakta oss »</a></p>
                        </div>
                        <hr className="mx-16"></hr>
                        <div className="mb-4 container-padding">
                            <div className="row display-flex align-items-center status-list-container">
                                <div className="status-list-icon"><i className={"pr-4 vismaicon vismaicon-filled vismaicon-success"}></i></div>
                                <div className="col-md-3"><p className="font-weight-bold">{migration.customerName}</p></div>
                                <div className="col-md-3"><p className="font-weight-bold">{migration.organizationNumber}</p></div>
                                <div className="col-md-3"><p><strong>Startad av:</strong> {migration.owner.name}</p></div>
                            </div>
                            <div className="save-log-button-container">
                                <button type="button" onClick={() => urlHelper.startService(migration.customerId)} className="btn float-right">Starta eEkonomi</button>
                                <button type="button" onClick={urlHelper.startMyServices} className="btn float-right">Mina tjänster</button>
                                {migration.warnings && migration.warnings.length > 0 && <button type="button" onClick={() => saveLogFile(migration)} className="btn btn-primary save-log-button mr-12">Spara log</button>}
                            </div>
                            <div>
                                <WarningLog migration={migration} expanded={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <Wootric email={user?.email || ''} timestamp={timestamp} />
            </div>
        </div>
    </>
}