import RightArrowIcon from "../Icons/RightArrowIcon";
import LoadingIcon from "../Icons/LoadingIcon";
import {useNavigate} from "react-router-dom";
import {routes} from "../../Constants/routes";

export default function AOSummarizedStatus(props: any) {

    const navigate = useNavigate();
    function onGoToStatusList() {
        if(props.status === "edit") {
            navigate(`${routes.startGuide()}`);
        } else {
            navigate(`${routes.aoStatusList(props.customerId, props.status)}`);
        }
    }

    if(!props.amount)
        return null;

    let statusIcon: any = null;
    if(props.status === "ongoing")
        statusIcon = <LoadingIcon></LoadingIcon>
    else if(props.status === "edit")
        statusIcon = <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-"+props.status}></i>
    else if(props.status === "collaboration")
        statusIcon = <i className={"pr-4 vismaicon vismaicon-filled vismaicon-warning"}></i>
    else
        statusIcon = <i className={"pr-4 vismaicon vismaicon-filled vismaicon-"+props.status}></i>

    return (<div className="mb-8 rounded bg-primary shadow-30 container-padding cursorpointer"
        onClick={onGoToStatusList}> 
        <div className="d-flex justify-content-between">
            {props.children}
            <span className="d-flex">
                {statusIcon}
                <p className="pl-4 pt-4 font-family-ubuntu-semibold">{props.amount} {props.title}</p>
            </span>
            <RightArrowIcon></RightArrowIcon>
        </div>

    </div>);
}