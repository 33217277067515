import Modal from "../Helpers/Modal";
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";

interface ConfirmDeleteMigrationProps {
    migrationId: string[];
}

export default function DeleteMigrationModal(props: ConfirmDeleteMigrationProps) {
    const migrationAction = useMigrationActionCreator();

    return (
        <>
            <Modal title="Bekräftelse av borttagning" onSave={() => {migrationAction.deleteMigrations(props.migrationId)}} mainButtonTitle="Ta bort">
                <h4>Är du säker på att du vill ta bort en eller flera dataflyttar?</h4>
            </Modal>
        </>
    );
}