import { UploadedFile } from "./MigrationUpload";
import React, { useState } from "react";
import { useInternalActionCreator } from "../../Actions/InternalActionCreator";
import { useHelpers } from "../../../Utils/helpers";
import Modal from "../Helpers/Modal";
import { CheckboxPure } from "../Helpers/Checkbox";
import { useAppDispatch } from "../../hooks";
import { onToggleModal } from "../../Reducers/modalReducer";
import { modalTypes } from "../../Constants/modalTypes";
import { SourceType } from "../Enums/CommonEnums";

export function ImportFilesInternalModal(props: { migrationId: string, files: UploadedFile[], sourceType?: SourceType }) {

    const source = props.sourceType !== undefined ? props.sourceType : SourceType.CsvFiles;

    const getFilteredFiles = () => {

        // files that are included in for both csv and compact
        const includedFiles = [".se"];

        source === SourceType.CsvFiles ? includedFiles.push(".csv") : includedFiles.push(".xcr");

        return props.files.filter(f => includedFiles.some(ext => f.filename.toLowerCase().endsWith(ext)));
    }

    const filteredFiles = getFilteredFiles();

    const [selectedFiles, setSelectedFile] = useState([] as UploadedFile[]);
    const actions = useInternalActionCreator();

    const dispatch = useAppDispatch();

    const helpers = useHelpers();
    const selectFile = (file: UploadedFile) => {

        if (selectedFiles.includes(file))
            setSelectedFile(selectedFiles.filter(f => f !== file));
        else
            setSelectedFile([...selectedFiles, file]);
    };

    const selectOrDeselectAll = () => {
        if (selectedFiles.length === filteredFiles.length)
            setSelectedFile([]); // deselect all if all are selected
        else
            setSelectedFile(filteredFiles); // select all
    };

    const importData = () => {
        actions.startImportFiles(props.migrationId, selectedFiles, source, () => {
            dispatch(onToggleModal(modalTypes.csvFileHandler));
        });
    };

    const modalTitle = source === SourceType.CsvFiles ? "Importera CSV" : "Importera Compact";

    const generateTableRows = (files: UploadedFile[]) => {
        return files.map((file, index) => (
            <tr key={index}>
                <td>{file.filename}</td>
                <td>{file.startDate && helpers.formatDateAndTime(file.startDate, false)}</td>
                <td>{file.endDate && helpers.formatDateAndTime(file.endDate, false)}</td>
                <td>
                    <CheckboxPure
                        key={file.id}
                        customClass="mb-0"
                        id={file.id}
                        value={selectedFiles.some(f => f.id === file.id)}
                        onChange={() => selectFile(file)}
                    />
                </td>
            </tr>
        ));
    };

    return (
        <Modal
            title={modalTitle}
            hideButtons={false}
            onSave={importData}
            mainButtonTitle="Importera"
            checkboxChecked={selectedFiles.length}
            size="modal-lg"
        >
            <h3>Välj filer att importera</h3>
            <table className="table table-stripe">
                <thead>
                    <tr>
                        <th>Filnamn</th>
                        <th>Startdatum (SIE)</th>
                        <th>Slutdatum (SIE)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {generateTableRows(filteredFiles)}
                </tbody>
            </table>
            <button className="btn btn-default" onClick={selectOrDeselectAll}>Markera alla</button>
        </Modal>
    );
}