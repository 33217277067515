import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useMigrationActionCreator} from "../../../Actions/MigrationActionCreator";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import { useEffect } from "react";
import DropDown from "../../Helpers/Dropdown";
import {LonEaStartGuideObject, setLonEaCustomerInfoValue} from "../../../Reducers/lonEaStartGuideReducer";
import "../../Helpers/CSS/DatePicker.css";

interface Props {
    customerInfo: LonEaStartGuideObject['customerInfo'];
    validationErrors: { [key: string]: string };
}

export default function LonEaCustomerInfo(props: Props) {
    const { customerInfo, validationErrors } = props;
    const migrationActions = useMigrationActionCreator();
    const bankListState = useAppSelector(state => state.bankList.bankList);

    const dispatch = useAppDispatch();

    useEffect(() => {
        migrationActions.getAllBanks();
    }, [])

    const onFieldChange = (field: keyof Props['customerInfo'], value: any) => {
        dispatch(setLonEaCustomerInfoValue({ field: field, value: value }));
    };

    const renderAsterisk = (fieldName: keyof Props['customerInfo']) => {
        if (validationErrors && validationErrors[fieldName]) {
            return <span style={{ color: 'red' }}>*</span>;
        }
        return null;
    };

    function getBankValue(id:any){
        return bankListState.find(x => x.id === id);
    }

    return (
        <div className="d-flex justify-content-around py-32 flex-row">
            <div style={{width: "60%"}}>
                <div>
                    <p className="m-0">
                        {renderAsterisk("startDate")}
                        Räkenskapsår startdatum
                    </p>
                    <div className="form-group">
                        <DatePicker
                            showIcon
                            calendarIconClassName={"react-datepicker__custom-calendar-icon"}
                            toggleCalendarOnIconClick
                            selected={customerInfo.startDate}
                            onChange={(date) => date && onFieldChange('startDate', date)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control">
                        </DatePicker>
                    </div>
                </div>
                <div>
                    <p className="m-0">
                        {renderAsterisk("endDate")}
                        Räkenskapsår slutdatum
                    </p>
                    <div className="form-group">
                        <DatePicker
                            showIcon
                            calendarIconClassName={"react-datepicker__custom-calendar-icon"}
                            toggleCalendarOnIconClick
                            selected={customerInfo.endDate}
                            onChange={(date) => date && onFieldChange('endDate', date)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control">
                        </DatePicker>
                    </div>
                </div>
                <div>
                    <p className="m-0">
                        {renderAsterisk("bank")}
                        Bank
                    </p>
                    <DropDown list={bankListState}
                              elementWidth="12"
                              padding="p-0"
                              blankValue="Välj"
                              value={customerInfo.bank?.id}
                              onChange={(value: any) => onFieldChange("bank", getBankValue(value))}
                              blankOption={true}
                              error={validationErrors.bank}/>
                </div>
            </div>
        </div>
    );
}