import { useHelpers } from "../../../Utils/helpers";
import { InternalMigration } from "../../Reducers/internalMigrationReducer";
import { BookKeepingMethod, InvoiceReceiver } from "../Enums/CommonEnums";

export default function MigrationMain(props: { migration: InternalMigration }) {
    const mainCompany = !props.migration.aoMigration;
    const helpers = useHelpers();
    const company = props.migration?.eaConfiguration?.company;
    const collaborationOptions = props.migration.eaConfiguration?.collaborationOptions;
    const competitorConfig = props.migration.eaConfiguration?.competitorConfig;

    function GetInvoiceReceiverText() {
        if (!collaborationOptions)
            return "Saknas";
        return collaborationOptions.invoiceReceiver === InvoiceReceiver.Customer ? "Kund" : "Byrå";
    }

    function GetPeriodText() {
        if (!collaborationOptions) {
            if(props.migration?.eaConfiguration)
                return props.migration.eaConfiguration.agreementInvoicePeriod + " månader"
            return "Saknas";
        }
        return collaborationOptions.period + " månader"
    }

    function GetBookkeepingMethodText() {
        if (!company)
            return "Saknas";
        return company.bookKeepingMethod === BookKeepingMethod.Invoicing ? "Faktureringsmetoden" : "Kontantmetoden";
    }

    return (
        <div className="panel-body">
            <div className="d-flex justify-content-around mb-8">
                <span className="col-lg-4">
                    <div className="mb-4 mr-8 panel bg-primary shadow-30 rounded-10 container-padding">
                        <h3 className="font-weight-bold">Företagsinformation</h3>
                        <div className="col-lg-10">
                            <h4 className="text-primary font-weight-bold">Företagsnamn</h4>
                            <p>{props.migration.customerName ? props.migration.customerName : ""}</p>
                            <h4 className="text-primary font-weight-bold">Organisationsnummer</h4>
                            <p>{props.migration.organizationNumber ? props.migration.organizationNumber : ""}</p>
                            <h4 className="text-primary font-weight-bold">Bokföringsmetod</h4>
                            <p>{GetBookkeepingMethodText()}</p>
                            <h4 className="text-primary font-weight-bold">Bank</h4>
                            <p>{company ? (company.bank.name) : "Saknas"}</p>
                            <h4 className="text-primary font-weight-bold">Momsperiod</h4>
                            <p>{helpers.getVatPeriod(props.migration.eaConfiguration?.company?.vatPeriod)}</p>
                        </div>
                    </div>
                </span>
                <span className=" col-lg-4">
                    <div className="mb-4 mr-8 bg-primary rounded-10 shadow-30 container-padding">
                        <h3 className="font-weight-bold">Information om Visma eEkonomi</h3>
                        <div className="col-lg-10">
                            <h4 className="text-primary font-weight-bold">eEkonomi variant</h4>
                            <p>{helpers.geteAccountingVariant(collaborationOptions ?
                                collaborationOptions.eaccountingVariant :
                                props.migration.eaConfiguration?.variant)}</p>
                            <h4 className="text-primary font-weight-bold">Primärt bokföringskonto</h4>
                            <p>{company ? company.bookKeepingAccount : "Saknas"}</p>
                            <h4 className="text-primary font-weight-bold">Avtalsperiod</h4>
                            <p>{GetPeriodText()}</p>

                            <h4 className="text-primary font-weight-bold">Fakturamottagare</h4>
                            <p>{GetInvoiceReceiverText()}</p>
                        </div>
                    </div>
                    {competitorConfig !== null &&   
                    <div className="mb-4 mr-8 bg-primary rounded-10 shadow-30 container-padding mt-12">
                        <h3 className="font-weight-bold">Information kundens val för tomma fält</h3>
                        <div className="col-lg-10">
                            <h4 className="text-primary font-weight-bold">Kundregister ska flyttas som</h4>
                            <p>{competitorConfig?.sendCustomersAsPrivate ? "Privatpersoner" : (competitorConfig ? "Företagskunder" : "")}</p>
                            <h4 className="text-primary font-weight-bold">Betalningsvillkor kundregister</h4>
                            <p>{competitorConfig?.customTermsOfPayment}</p>
                            <h4 className="text-primary font-weight-bold">Betalningsvillkor leverantörsregister</h4>
                            <p>{competitorConfig?.supplierTermsOfPayment}</p>
                        </div>
                    </div>
                    }
                </span>
                <span className="col-lg-4">
                    <div className="mb-8 bg-primary rounded-10 shadow-30 container-padding">
                        <h3 className="font-weight-bold">Migreringskontakt</h3>
                        <div className="col-lg-10">
                            <h4 className="text-primary font-weight-bold">Kontaktperson</h4>
                            <p>{props.migration.owner ? props.migration.owner.name : "Saknas"}</p>
                            <h4 className="text-primary font-weight-bold">Mailadress</h4>
                            <p>{props.migration.owner ? props.migration.owner.email : "Saknas"}</p>
                        </div>
                    </div>
                    <div className="mb-4 bg-primary rounded-10 shadow-30 container-padding">
                        <h3 className="font-weight-bold">{mainCompany ? 'Information om huvudbolag' : 'Information om byrå'}</h3>
                        <div className="col-lg-10">
                            <h4 className="text-primary font-weight-bold">Företagsnamn</h4>
                            <p>{mainCompany ? props.migration.mainCustomer?.customerName : props.migration.aoMigration?.customerName}</p>
                            <h4 className="text-primary font-weight-bold">Kundnummer</h4>
                            <p>{mainCompany ? props.migration.mainCustomer?.customerNumber : props.migration.aoMigration?.customerNumber}</p>
                            <h4 className="text-primary font-weight-bold">Organisationsnummer</h4>
                            <p>{mainCompany ? props.migration.mainCustomer?.organizationNumber : props.migration.aoMigration?.organizationNumber}</p>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    );
}