import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingIcon from "../Icons/LoadingIcon";
import { useAppDispatch, useAppSelector } from "../../hooks";
import LeftArrowIcon from "../Icons/LeftArrowIcon";
import ButtonGroupWithFilter from "../Helpers/ButtonGroupWithFilter";
import PhoneFilterDropdown from "./PhoneFilterDropdown";
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import { Migration, storeMigrations } from "../../Reducers/migrationReducer";
import WarningLog from "../SharedComponents/WarningLog";
import { routes } from "../../Constants/routes";
import { useFortnoxActionCreator } from "../../Actions/FortnoxActionCreator";
import { MigrationStatus, SourceType } from "../Enums/CommonEnums";
import { useHelpers } from "../../../Utils/helpers";
import { useUrlHelper } from "../../Constants/urlHelper";
import Wootric from "../Wootric/Wootric";

export default function AODetailedStatusList() {
    const { id, status } = useParams();
    const migrationActions = useMigrationActionCreator();

    const fortnoxActions = useFortnoxActionCreator();
    const currentUser = useAppSelector(state => state.users.currentUser);
    const aoCustomers = useAppSelector(state => state.users.aoCustomers);
    const migration = useAppSelector((state) => state.migration.migrationList);
    const fortnoxState = useAppSelector((state) => state.fortnox);
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState("all");
    const [filteredMigration, setFilteredMigration] = useState(migration);
    const users = useAppSelector(state => state.migration.aoUsers);
    const dispatch = useAppDispatch();
    const [expandedLogs, setExpandedLogs] = useState<string[]>([]);
    const timestamp = Math.floor(Date.now() / 1000);

    const helper = useHelpers();
    const urlHelper = useUrlHelper();
    useEffect(() => {
        if (aoCustomers !== null && aoCustomers.length > 0) {
            dispatch(storeMigrations([]));
            //const currentAo = aoCustomers.find((customer: { customerId: string; }) => customer.customerId === id);
            const currentAo = aoCustomers.find((customer => customer.customerId === id));

            if (currentAo)
                migrationActions.getMigrationsForStatusList(currentAo, status);
            else {
                // This should never happen
            }

        } else {
            migrationActions.getMigrationsForCurrentUser();
        }
    }, [aoCustomers, status])

    useEffect(() => {
        setFilteredMigration(migration);
    }, [migration])

    useEffect(() => {

    }, [expandedLogs])

    const hasAdditionalInformation = filteredMigration.some(mig => mig.additionalInformation);
    let statusIcon: any = null;
    let headLine: string;
    if (status === "ongoing") {
        statusIcon = <LoadingIcon></LoadingIcon>
        headLine = "Pågående jobb";
    } else if (status === "edit") {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-" + status}></i>
        headLine = "Pågående";
    } else {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-filled vismaicon-" + status}></i>
        switch (status) {
            case "error":
                if (hasAdditionalInformation) {
                    headLine = "Att hantera (kräver åtgärd)";
                } else {
                    headLine = ""; 
                }
                break;
            case "success":
                headLine = "Slutförda överföringar";
                break;
            case "warning":
                headLine = "Kräver åtgärd";
                break;
            default:
                headLine = "";
                break;
        }
    }

    const editIcon: any = <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-edit"}></i>

    function filterMigrations(filterType: string, filterBy?: any) {

        setActiveButton(filterType);
        if (filterType === "started" || filterType === "assigned") {
            setFilteredMigration(migration.filter(mig => filterType === "assigned" ? mig.assignedTo?.name === filterBy : mig.owner.name === filterBy));
        } else {
            setFilteredMigration(migration);
        }
    }

    function saveLogFile(mig: any) {
        migrationActions.getLogFile(mig);
    }

    const renderMigrationInstructions = () => {
        if (status !== "ongoing" && filteredMigration.length > 0) {
          const hasError = filteredMigration.some(mig => mig.status === MigrationStatus.Error);
      
          if (hasAdditionalInformation || hasError) {
            return (
              <div className="ml-16 rounded bg-primary shadow-30 container-padding mb-32">
                {hasAdditionalInformation && (
                  <div className="d-flex mb-16 justify-content-start">
                    <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-edit mr-24"}></i>
                    <p>Komplettering behövs. Klicka på pennan för att redigera.</p>
                  </div>
                )}
                {hasError && (
                  <div className="d-flex justify-content-start">
                    <i className={"pr-4 vismaicon vismaicon-filled vismaicon-error mr-24"}></i>
                    <p>Hanteras av Visma Spcs</p>
                  </div>
                )}
              </div>
            );
          }
        }
    }

    function setExpanded(id: any, iconId: string) {

        let icon = document.getElementById(iconId);

        if (icon)
            icon.classList.toggle('rotated')

        let logs = [...expandedLogs]

        if (expandedLogs.some(x => x === id)) {
            let index = logs.indexOf(id)
            logs.splice(index, 1)
            setExpandedLogs(logs)
        }
        else {
            logs.push(id)
            setExpandedLogs(logs)
        }

    }

    function isExpanded(id: any) {
        return expandedLogs.some(x => x === id)
    }

    if (!migration)
        return null;

    const reAuthorizeFortnox = (mig: Migration) => {
        fortnoxActions.getAuthUriReconnect(mig.id, window.location.href);
    }

    return (
        <>
            <div className="d-flex cursorpointer" onClick={() => navigate(routes.summarizedList)}>
                <LeftArrowIcon /><h3 className="font-weight-bold">Tillbaka</h3>
            </div>
            <div className="panel pb-48">
                <div className="container-padding">

                    <div>
                        <h3 className="pl-4 mb-24 pt-4 font-family-ubuntu-semibold">{headLine}</h3>
                        <span className="phone-view-filter">
                            <PhoneFilterDropdown
                                filterMigrations={(filterType: string, filterBy: any) => filterMigrations(filterType, filterBy)}
                                startedByDropdown={users} assignedToDropdown={users} />
                        </span>
                        <div>
                            <div className="w100 d-flex justify-content-between">
                                <div>
                                    {renderMigrationInstructions()}
                                </div>
                                <div>
                                    <ButtonGroupWithFilter
                                        filterMigrations={(filterType: string, filterBy: any) => filterMigrations(filterType, filterBy)}
                                        activeButton={activeButton} startedByDropdown={users}
                                        assignedToDropdown={users} />
                                </div>
                            </div>

                        </div>

                    </div>
                    <div>
                        {status === "success" && <div className="mb-32 mt-16" style={{ width: "50%" }}>
                            <h2>Nu är flytten klar och du är välkommen in i Visma eEkonomi!</h2>
                            <p>Vi vill att du går igenom ditt företag och ser till att du är nöjd med flytten, innan du börjar arbeta i ditt nya program.
                                Här nedanför får du information om vad som har hänt i samband med flytten och du kan enkelt spara denna logg för att titta på den i lugn och ro.
                                Till din hjälp har du även vårt hjälpavsnitt: <a href="https://www.vismaspcs.se/visma-support/visma-administration-2000/content/online-help/eekonomi-for-over-uppgifter-foretag.htm#efter" target="_blank">Checklista efter flytt till Visma eEkonomi »</a></p>
                            <p>Hoppas du ska trivas i ditt nya program!</p>
                            <p>Saknar du något eller har frågor om din flytt? - <a href="https://vismaspcs.se/support/visma-eekonomi#hybrid" target="_blank">Kontakta oss »</a></p>
                        </div>}
                        {filteredMigration.map((mig: Migration, index: number) => {
                            return (
                                <div key={mig.id}>
                                    <hr className="mx-16"></hr>
                                    <div className="mb-4 container-padding">
                                        <div className="row display-flex align-items-center status-list-container">
                                            {mig.additionalInformation && mig.status !== MigrationStatus.Done ?
                                                <Link className="nav-list-item" to={routes.additionalInfo(mig.id)}><div className="status-list-icon">{editIcon}</div></Link> :
                                                <div className="status-list-icon">{statusIcon}</div>
                                            }
                                            <div className="col"><p className="font-weight-bold">{mig.customerName}</p></div>
                                            <div className="col"><p className="font-weight-bold">{mig.organizationNumber}</p></div>
                                            <div className="col"><p><strong>Startad av:</strong> {mig.owner.name}</p></div>

                                            <div className="col">
                                                <p><strong>Källa: </strong>{helper.getSourceType(mig.sourceType)}</p>
                                            </div>
                                            {mig.sourceType === SourceType.Fortnox && mig.status === MigrationStatus.AuthenticationFailed &&
                                                <div className="col">
                                                    <button className="btn" onClick={() => reAuthorizeFortnox(mig)}>Anslut till fortnox</button>
                                                </div>
                                            }

                                            {mig.customerId && mig.status === MigrationStatus.Done &&
                                                <div className="col">
                                                    <button className="btn" onClick={() => urlHelper.startService(mig.customerId)}>Starta Visma eEkonomi</button>
                                                </div>
                                            }
                                            {status === "success" && <span id={`icon` + index} className="vismaicon vismaicon-arrow-down-circle warning-log-icon" onClick={() => setExpanded(mig.id, ("icon" + index))} aria-hidden="true"></span>}
                                        </div>
                                        {isExpanded(mig.id) && <>
                                            {mig.warnings.length > 0 && <div className="save-log-button-container">
                                                <button type="button" onClick={() => saveLogFile(mig)} className="btn btn-primary save-log-button">Spara logg</button>
                                            </div>}
                                            <div>
                                                <WarningLog migration={mig} expanded={isExpanded(mig.id)} />
                                            </div>
                                        </>}
                                    </div>
                                </div>);
                        })}
                    </div>
                </div>
                {status === "success" && <Wootric email={currentUser?.email || ""} timestamp={timestamp} />}
            </div>
        </>
    );
}