import DropDown from "../Helpers/Dropdown";
import { startGuideDropdowns } from "../../Constants/dropdownLists";
import TextInput from "../Helpers/TextInput";
import {
    CollaborationOption,
    setCollaborationInvoicingInformation,
    setCollaborationValue,
    setPrimaryContactInformation,
    StartGuideObject
} from "../../Reducers/startguideReducer";
import React, { useState } from "react";
import { onToggleModal } from "../../Reducers/modalReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import { setValue } from "../../Reducers/dropdownReducer";
import ConfirmStartGuideDataModal from "./ConfirmStartGuideDataModal";
import ProductInformationModal from "./ProductInformationModal";
import BaseSearch from "../Helpers/BaseSearch";
import { CollaborationStatus, EaccountingVariant, InvoicePeriod, InvoiceReceiver } from "../Enums/CommonEnums";
import { ErrorList } from "../../Actions/MigrationActionCreator";

interface CollaborationsGuidaProps {
    currentPage: number;
    description: string;
    hasAoSimplicity: boolean;
    collaborations: StartGuideObject[];
    errors:ErrorList[] | null;
    onSaveClick: Function;
}

export default function CollaborationsGuide(props: CollaborationsGuidaProps) {
    const dispatch = useAppDispatch();
    const dropdownState = useAppSelector(state => state.dropdown);
    const migrationActions = useMigrationActionCreator();
    const startGuideState = useAppSelector(state => state.startGuide.migrations);
    const collaborationItems = props.collaborations;

    const vonEmailsState = useAppSelector(state => state.startGuide.vonEmails)
    const [filteredEmailList, setEmailList] = useState<string[]>([]);
    const activeModal = useAppSelector((state) => state.modal.modal);

    const hasCollaborations = collaborationItems.length > 0;

    function changeAllTextValue(value: string, field: string) {
        collaborationItems.forEach((migration: StartGuideObject) => {
            dispatch(setCollaborationValue({ id: migration.id, field, value }));
        })
    }

    function changeAllProductValues(value: EaccountingVariant) {
        dispatch(setValue({ id: "product", value }));
        collaborationItems.forEach((migration: StartGuideObject) => {
            if (filterSelectableProducts(migration, startGuideDropdowns.product).some(e => value || e.id === parseInt(value))) {
                changeProductValue(migration, value)
            }
        })
    }

    function changeAllInvoiceReceiverValues(value: InvoiceReceiver) {
        dispatch(setValue({ id: "invoiceReceiver", value }));

        collaborationItems.forEach((migration: StartGuideObject) => {
            if (!checkIfNoneInteractive(migration.eaConfiguration.collaborationOptions)) {
                changeInvoiceReceiverValue(migration, value)
            }
        })
    }

    function changeAllInvoicePeriodValues(value: InvoicePeriod) {
        dispatch(setValue({ id: "invoicePeriod", value }));

        collaborationItems.forEach((migration: StartGuideObject) => {

            if (!disableInvoicePeriod(migration.eaConfiguration.collaborationOptions)) {
                dispatch(setCollaborationValue({ id: migration.id, field: "period", value }));
            }
        })
    }

    function changeProductValue(migration: StartGuideObject, variant: EaccountingVariant) {

        dispatch(setCollaborationValue({ id: migration.id, field: "eaccountingVariant", value: variant }))
        const invoiceReceiver = getDefaultInvoiceReceiverByProduct(variant);
        if (invoiceReceiver)
            changeInvoiceReceiverValue(migration, invoiceReceiver)
        const invoicePeriod = getDefaultInvoicePeriodByProduct(variant)
        if (invoicePeriod)
            dispatch(setCollaborationValue({ id: migration.id, field: "period", value: invoicePeriod }));
    }

    function changeInvoiceReceiverValue(migration: StartGuideObject, value: InvoiceReceiver) {
        dispatch(setCollaborationValue({ id: migration.id, field: "invoiceReceiver", value: value }));
    }

    function filterSelectableProducts(migration: any, products: any[]) {
        let filteredProducts = products;

        if (migration.customerHasVonAccount) {
            filteredProducts = filteredProducts.filter((product) => product.id !== 1);
        }

        return filteredProducts
    }

    function filterSelectableInvoicePeriods(migration: any, periods: any[]) {
        let filteredPeriods = periods;

        if ((migration.eaConfiguration.collaborationOptions.invoicePeriod != '2') || (migration.eaConfiguration.collaborationOptions.invoicePeriod === '2' && !props.hasAoSimplicity)) {
            filteredPeriods = filteredPeriods.filter((period) => period.id !== 1);
        }

        return filteredPeriods
    }

    function getDefaultInvoiceReceiverByProduct(variant: EaccountingVariant): InvoiceReceiver | null {
        return variant === EaccountingVariant.noninteractive ? InvoiceReceiver.AccountingOffice : null;
    }

    function getDefaultInvoicePeriodByProduct(variant: EaccountingVariant): InvoicePeriod | null {
        return variant === EaccountingVariant.noninteractive ? InvoicePeriod.Yearly : null;
    }

    function checkIfNoneInteractive(collaborationOption: CollaborationOption): boolean {
        return collaborationOption.eaccountingVariant === EaccountingVariant.noninteractive;
    }

    function disableInvoicePeriod(collaborationOption: CollaborationOption): boolean {
        return collaborationOption.period == InvoicePeriod.Monthly && props.hasAoSimplicity ||
            collaborationOption.eaccountingVariant == EaccountingVariant.noninteractive
    }

    function checkIndex(): Number {
        return startGuideState.findIndex(migration => migration.collaborationConfig.canCreateNonInteractiveCollaboration && !migration.collaborationConfig.hasAlreadyCollaboration)
    }

    const showContactFields = (migration: StartGuideObject): boolean => {
        const config = migration.collaborationConfig;
        return migration.eaConfiguration.collaborationOptions.eaccountingVariant > EaccountingVariant.noninteractive && !config.inviteWillBeSent;
    };

    function handleSearch(email: string) {
        setEmailList(vonEmailsState.filter(x => x.includes(email)))
    }


    function renderNoCollaborations() {
        return <div className="alert alert-info">
            <p>Du har inga nya företag att skapa samarbeten för. Gå vidare till nästa steg.</p>
        </div>;
    }

    function getError(id: string, field: string): string | null {
        if (props.errors) {
            const errorList = props.errors.find(obj => obj.id === id);

            if (errorList) {
                const error = errorList.errors.find(obj => obj.field === field);

                if (error) {
                    return error.message
                }
            }
        }

        return null;
    }

    function renderAoMigrationAlerts(migration: StartGuideObject) {

        return <>
            {migration.collaborationStatus === CollaborationStatus.clientInvited && <div className="alert alert-info alert-sm"><p><b>Samarbetsförfrågan har skickats</b>. Väntar på godkännande från kunden.</p></div>}
            {!migration.collaborationConfig.hasMultipleCustomers && migration.collaborationStatus !== CollaborationStatus.clientInvited && migration.collaborationConfig.inviteWillBeSent && <div className="alert alert-info alert-sm"><p>Företaget <b>{migration.organizationNumber}</b> finns redan i Visma Online så en samarbetsförfrågan kommer att skickas.</p></div>}
            {migration.collaborationConfig.hasMultipleCustomers && <div className="alert alert-error alert-sm">Det finns mer än ett företag med samma organisationsnummer <b>{migration.organizationNumber}</b> i Visma Online. För att kunna gå vidare behöver du skapa samarbetet genom Advisor eller Byråstöd istället.</div>}
        </>
    }

    return (
        <>
            {activeModal === "productInformationModal" && <ProductInformationModal />}
            {props.currentPage === 1 && <>
                <div className="panel-body no-top-bottom-padding">
                    {!hasCollaborations && renderNoCollaborations()}
                    {hasCollaborations &&
                        <div className="row display-flex align-items-center">
                            <span className="col-2">
                                <div>
                                    <p className="font-weight-bold">{props.description}</p>
                                </div>
                            </span>
                            <div className="action col-5">
                                <DropDown list={startGuideDropdowns.product} label={"Produkt"}
                                    alignment={"align-items-end"}
                                    icon={"vismaicon vismaicon-sm vismaicon-filled vismaicon-info"}
                                    iconClick={() => dispatch(onToggleModal("productInformationModal"))}
                                    value={dropdownState.inputs["product"]}
                                    onChange={(value: EaccountingVariant) => changeAllProductValues(Number(value))}
                                    elementWidth={8} />
                            </div>
                            <div className="action col-5 align-items-end form-group full-width d-flex flex-column">
                                <label className="col-xs-8 control-label">Kundansvarig på byrån (e-post)<span
                                    className={`vismaicon vismaicon-sm vismaicon-filled vismaicon-help label-icon`}
                                    style={{ cursor: "help" }}
                                    data-title={'Ange den e-postadress som ska stå som avsändare för samarbetsinbjudan till kund, t.ex kundansvarig eller uppdragsansvarig på din byrå. Det är också användaren med denna e-postadress som blir upplagd och får tillgång till Visma eEkonomi. Om det är flera användare som ska ha tillgång till tjänsten styrs detta via uppdraget i Visma Byråstöd eller Visma Advisor'}
                                    aria-hidden="true"></span></label>
                                <BaseSearch
                                    options={filteredEmailList} handleSearch={handleSearch} className={"col-xs-8 "}
                                    placeholder={"Sök på e-post..."}
                                    handleSelect={(value: string) => changeAllTextValue(value[0], "consultantEmail")} />
                            </div>
                        </div>
                    }
                </div>
                <hr className="first-line"></hr>
                {collaborationItems.map((migration: StartGuideObject, index: number) => {
                    return <div key={index}>
                        {checkIndex() !== index && <hr className="mx-24"></hr>}
                        <div className="panel-body no-top-bottom-padding">
                            {renderAoMigrationAlerts(migration)}
                            <div className="row align-items-center ">
                                <span className="col-2">
                                    <div>
                                        <p className="font-weight-bold">{migration.customerName}</p>
                                        <p className="font-weight-bold">{migration.organizationNumber}</p>
                                    </div>
                                </span>
                                <div className="action col-5">
                                    <DropDown list={filterSelectableProducts(migration, startGuideDropdowns.product)}
                                        label={"Produkt *"}
                                        alignment={"align-items-end"}
                                        value={migration.eaConfiguration.collaborationOptions.eaccountingVariant}
                                        onChange={(value: EaccountingVariant) => changeProductValue(migration, Number(value))}
                                        elementWidth={8}
                                        error={getError(migration.id, "eaccountingVariant")} />
                                </div>
                                <div className="action col-5 align-items-end form-group full-width d-flex flex-column">
                                    <label className="col-xs-8 control-label">Kundansvarig på byrån (e-post)</label>
                                    <BaseSearch options={filteredEmailList} handleSearch={handleSearch} placeholder={"Sök på e-post..."}
                                        className={"col-xs-8 "}
                                        error={getError(migration.id, "consultantEmail")}
                                        selected={migration.eaConfiguration.collaborationOptions.consultantEmail ? new Array(migration.eaConfiguration.collaborationOptions.consultantEmail) : null}
                                        handleSelect={(value: any) => dispatch(setCollaborationValue({
                                            id: migration.id,
                                            value: value[0],
                                            field: "consultantEmail"
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                        {showContactFields(migration) && <div className="panel-body no-top-bottom-padding">
                            <div className="row align-items-center ">
                                <span className="col-2">

                                </span>
                                <div className="action col-5">
                                    <TextInput label={"Förnamn *"}
                                        alignment={"align-items-end"}
                                        labelWidth={8}
                                        noAutoComplete={true}
                                        value={migration.eaConfiguration.collaborationOptions.primaryContact.firstName}
                                        onChange={(value: any) => dispatch(setPrimaryContactInformation({
                                            id: migration.id,
                                            value,
                                            field: "firstName"
                                        }))}
                                        elementWidth={8}
                                        error={getError(migration.id, "firstName")} />
                                </div>
                                <div className="action col-5">
                                    <TextInput label={"Efternamn *"}
                                        alignment={"align-items-end"}
                                        labelWidth={8}
                                        noAutoComplete={true}
                                        value={migration.eaConfiguration.collaborationOptions.primaryContact.lastName}
                                        onChange={(value: any) => dispatch(setPrimaryContactInformation({
                                            id: migration.id,
                                            value,
                                            field: "lastName"
                                        }))}
                                        elementWidth={8}
                                        error={getError(migration.id, "lastName")} />
                                </div>
                            </div>
                            <div className="row align-items-center ">
                                <span className="col-2">

                                </span>
                                <div className="action col-5">
                                    <TextInput label={"Telefon *"}
                                        alignment={"align-items-end"}
                                        labelWidth={8}
                                        noAutoComplete={true}
                                        value={migration.eaConfiguration.collaborationOptions.primaryContact.mobile}
                                        onChange={(value: any) => dispatch(setPrimaryContactInformation({
                                            id: migration.id,
                                            value,
                                            field: "mobile"
                                        }))}
                                        elementWidth={8}
                                        error={getError(migration.id, "mobile")} />
                                </div>
                                <div className="action col-5">
                                    <TextInput label={"E-post *"}
                                        alignment={"align-items-end"}
                                        labelWidth={8}
                                        noAutoComplete={true}
                                        value={migration.eaConfiguration.collaborationOptions.primaryContact.email}
                                        onChange={(value: any) => dispatch(setPrimaryContactInformation({
                                            id: migration.id,
                                            value,
                                            field: "email"
                                        }))}
                                        elementWidth={8}
                                        error={getError(migration.id, "email")} />
                                </div>
                            </div>
                            <div className="row align-items-center ">
                                <span className="col-2">

                                </span>
                                <div className="action col-5">
                                    <TextInput label={"Adress *"}
                                        alignment={"align-items-end"}
                                        labelWidth={8}
                                        noAutoComplete={true}
                                        value={migration.eaConfiguration.collaborationOptions.invoicingInformation.address1}
                                        onChange={(value: any) => dispatch(setCollaborationInvoicingInformation({
                                            id: migration.id,
                                            value,
                                            field: "address1"
                                        }))}
                                        elementWidth={8}
                                        error={getError(migration.id, "address1")} />
                                </div>
                                <div className="action col-5">
                                    <TextInput label={"Stad *"}
                                        alignment={"align-items-end"}
                                        labelWidth={8}
                                        noAutoComplete={true}
                                        value={migration.eaConfiguration.collaborationOptions.invoicingInformation.city}
                                        onChange={(value: any) => dispatch(setCollaborationInvoicingInformation({
                                            id: migration.id,
                                            value,
                                            field: "city"
                                        }))}
                                        elementWidth={8}
                                        error={getError(migration.id, "city")} />
                                </div>
                            </div>
                            <div className="row align-items-center ">
                                <span className="col-2">

                                </span>
                                <div className="action col-5">
                                    <TextInput label={"Postnummer *"}
                                        alignment={"align-items-end"}
                                        labelWidth={8}
                                        noAutoComplete={true}
                                        value={migration.eaConfiguration.collaborationOptions.invoicingInformation.postalCode}
                                        onChange={(value: any) => dispatch(setCollaborationInvoicingInformation({
                                            value: value,
                                            id: migration.id,
                                            field: "postalCode"
                                        }))}
                                        elementWidth={8}
                                        error={getError(migration.id, "postalCode")} />
                                </div>
                            </div>
                        </div>
                        }

                    </div>
                })}
            </>}
            {props.currentPage === 2 && <>
                <div className="panel-body no-top-bottom-padding">
                    {!hasCollaborations && renderNoCollaborations()}
                    {hasCollaborations &&
                        <div className="row display-flex align-items-center">
                            <span className="col-2">
                                <div>
                                    <p className="font-weight-bold">{props.description}</p>
                                </div>
                            </span>
                            <div className="action col-5">
                                <DropDown list={startGuideDropdowns.invoiceReceiver} label={"Fakturamottagare"}
                                    alignment={"align-items-end"}
                                    value={dropdownState.inputs["invoiceReceiver"]}
                                    onChange={(value: InvoiceReceiver) => changeAllInvoiceReceiverValues(Number(value))}
                                    blankOption={true} elementWidth={8} />
                            </div>
                            <div className="action col-5">
                                <DropDown list={startGuideDropdowns.invoicePeriod.filter((period => period.id !== 1))}
                                    label={"Avtalsperiod"}
                                    alignment={"align-items-end"}
                                    value={dropdownState.inputs["invoicePeriod"]}
                                    onChange={(value: InvoicePeriod) => changeAllInvoicePeriodValues(Number(value))}
                                    blankOption={true} elementWidth={8} />
                            </div>
                        </div>
                    }
                </div>
                <hr className="first-line"></hr>
                {collaborationItems.map((migration: StartGuideObject, index: number) => {
                    return <span key={index}>
                        {checkIndex() !== index && <hr className="mx-24"></hr>}
                        <div className="panel-body no-top-bottom-padding">
                            {renderAoMigrationAlerts(migration)}
                            <div className="row display-flex align-items-center">
                                <span className="col-2">
                                    <div>
                                        <p className="font-weight-bold">{migration.customerName}</p>
                                        <p className="font-weight-bold">{migration.organizationNumber}</p>
                                    </div>
                                </span>
                                <div className="action col-5">
                                    <DropDown list={startGuideDropdowns.invoiceReceiver} label={"Fakturamottagare *"}
                                        alignment={"align-items-end"}
                                        value={migration.eaConfiguration.collaborationOptions.invoiceReceiver}
                                        onChange={(value: InvoiceReceiver) => changeInvoiceReceiverValue(migration, Number(value))}
                                        blankOption={true} elementWidth={8}
                                        disabled={checkIfNoneInteractive(migration.eaConfiguration.collaborationOptions)}
                                        error={getError(migration.id, "invoiceReceiver")} />
                                </div>
                                <div className="action col-5">
                                    <DropDown
                                        list={filterSelectableInvoicePeriods(migration, startGuideDropdowns.invoicePeriod)}
                                        alignment={"align-items-end"}
                                        label={"Avtalsperiod *"}
                                        value={migration.eaConfiguration.collaborationOptions.period}
                                        onChange={(value: InvoicePeriod) => dispatch(setCollaborationValue({
                                            id: migration.id,
                                            field: "period",
                                            value: Number(value)
                                        }))}
                                        blankOption={true} elementWidth={8}
                                        disabled={disableInvoicePeriod(migration.eaConfiguration.collaborationOptions)}
                                        error={getError(migration.id, "period")}  />
                                </div>
                            </div>
                        </div>
                    </span>
                })}

                {hasCollaborations &&
                    <div className="panel-body">
                        <div className="alert alert-info">
                            <p>Skapa samarbeten för att sedan fortsätta med guiden och fylla i fler uppgifter!</p>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-primary" onClick={props.onSaveClick()}>Skapa samarbeten</button>
                        </div>
                    </div>
                }
            </>}
            {activeModal === "confirmSaveStartGuide" && <ConfirmStartGuideDataModal handleCollaborations={true} collaborations={collaborationItems} migrations={[]} />}
        </>
    )
}