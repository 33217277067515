import {useEffect, useState} from 'react';
import CustomerInfo from './CustomerInfo';
import {useAppSelector} from "../../../hooks";
import ConfirmInformation from './ConfirmInformation';
import '../SMB.css';
import {StartGuideData, useMigrationActionCreator} from "../../../Actions/MigrationActionCreator";
import * as formValidationRules from '../../../Constants/formValidationRules';
import {SourceType} from "../../Enums/CommonEnums";
import {useParams} from "react-router-dom";
import YogaMan from "../../Images/Pictogram-yoga-man.svg";
import Guide from "../Guide";


export default function SMBStartGuide() {
    const [activeStep, setActiveStep] = useState(1);
    const startGuideState = useAppSelector(state => state.smbStartGuide).startGuide;
    const migration = useAppSelector(state => state.migration).migrationDetails
    const migrationActions = useMigrationActionCreator();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

    const {sourceType} = useParams();

    useEffect(() => {
        if(sourceType) {
            migrationActions.getSmbMigrationDetails(Number(sourceType) as SourceType)
        }
    }, [])

    if(!migration)
        return null

    let stepId = 1;
    
    const validateCustomerInfoStep = () => {
        const { customerInfo } = startGuideState;
        const errors: { [key: string]: string } = {};

        formValidationRules.customerInfoForm.forEach(({ inputId, rule, message }) => {
            const value = customerInfo[inputId as keyof typeof customerInfo];

            if (inputId === "bank") {
                if (!value || !(value as any).id ) {
                    errors[inputId] = message;
                }
            }

            else if(!((rule === 'required' && value) || (new RegExp(rule).test(value.toString())))) {
                errors[inputId] = message;
            }
        });

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const steps = [
        {
            id: stepId++,
            label: 'Kunduppgifter',
            content: <CustomerInfo customerInfo={startGuideState.customerInfo} validationErrors={validationErrors} />,
            complete: (startGuideState.isCustomerInfoComplete),
            validate: validateCustomerInfoStep,
        }
    ];

    steps.push({
        id: stepId++, label: 'Slutför',
        content: <ConfirmInformation startGuide={startGuideState} />,
        complete: activeStep > steps.length,
        validate: () => true
    })

    const target = "Visma eEkonomi";

    const saveStartGuideData = (goToNextStep: () => void) => {
        
        const data:StartGuideData= {
            id: migration.id,
            company: {
                bookKeepingMethod: startGuideState.customerInfo.bookKeepingMethod,
                bank: startGuideState.customerInfo.bank,
                vatPeriod: startGuideState.customerInfo.vatPeriod,
                bookKeepingAccount: startGuideState.customerInfo.bookingAccount
            },
            product: startGuideState.product
        }

        migrationActions.saveStartGuideDataSmb(data, goToNextStep)
    };

    return (
        <>
            {migration.eAccountingVariant <= 0 ?
                <div className="d-flex justify-content-center position-absolute align-items-center top-0 bottom-0 right-0 left-0">
                    <div className="d-flex justify-content-center col-sm-3 flex-column">
                        <img src={YogaMan} alt="Man in yoga position" />
                        <h3 className="text-center bold">
                            <span>Du behöver köpa eEkonomi innan du kan gå vidare. </span>
                        </h3>
                        <h3 className="text-center bold">
                            <a href="https://vismaspcs.se/produkter/bokforingsprogram/visma-eekonomi" target="_blank" rel="noopener noreferrer">
                            Klicka här.
                            </a>
                        </h3>
                    </div>
                </div>
                :
                <Guide steps={ steps } onCompleteClick={ saveStartGuideData } target={ target } />
            }
        </>
    );
}