import { useEffect, useRef } from "react";
import ReactModal from "react-modal";
import Loader from "./Loader";
import { useAppDispatch } from "../../hooks";
import { modalSlice } from "../../Reducers/modalReducer";

interface ModalAlertProps {
    mainButtonTitle: string;
    mainButtonDisabled: boolean;
    title: string;
    children: React.ReactNode;
    onSave(): void;
}

function useOnClickOutside(ref: any, handler: any) {
    useEffect(
        () => {
            const listener = (event: any) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );
}

export function ModalAlert(props: ModalAlertProps) {

    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);

    const styles = {
        content: {
            top: '5%',
            overflow: 'scroll'
        },
        overlay: {
            backgroundColor: 'rgba(40, 40, 40, 0.5)'
        },
    };

    const onCloseModal = () => dispatch(modalSlice.actions.onToggleModal(""))

    const onSave = () => {
        props.onSave();
        onCloseModal();
    }

    return <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick={true}
        contentLabel="Modal"
        ariaHideApp={false}
        className='modal fade in modal-info modal-custom-styles'
        style={styles}
    >
        <div className="modal-dialog" ref={ref}>
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onCloseModal()}><span aria-hidden="true">&times;</span></button>
                    <h2 className="modal-title">{props.title}</h2>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
                <div className="modal-footer">
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" disabled={props.mainButtonDisabled} onClick={() => onSave()}>{props.mainButtonTitle || 'Spara'}</button>
                        {/*<button type="button" className="btn" onClick={() => onCloseModal()}>Avbryt</button>*/}
                    </div>
                    <Loader />
                </div>
            </div>
        </div>
    </ReactModal>
}

export default function Modal(props: any) {

    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside(ref, () => onCloseModal());

    const styles = {
        content: {
            top: '5%',
            overflow: 'scroll'
        },
        overlay: {
            backgroundColor: 'rgba(40, 40, 40, 0.5)'
        },
    };

    const onCloseModal = () => dispatch(modalSlice.actions.onToggleModal(""))

    const onSave = () => {
        props.onSave();

        if(props.closeAfterSave)
            onCloseModal();
    }

    return (
        <ReactModal
            isOpen={true}
            shouldCloseOnOverlayClick={true}
            contentLabel="Modal"
            ariaHideApp={false}
            className='modal fade in'
            style={styles}
        >
            <div className={`modal-dialog ${props.size}`} ref={ref}>
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onCloseModal()}><span aria-hidden="true">&times;</span></button>
                        <h2 className="modal-title">{props.title}</h2>
                    </div>
                    <div className="modal-body" style={props.hideButtons ? { marginBottom:'0px'} : {}}>
                        {props.children}
                    </div>
                    <div className="modal-footer">
                        {!props.hideButtons && <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-primary" disabled={props.mainButtonDisabled || props.checkboxChecked != undefined ? !props.checkboxChecked : false} onClick={() => onSave()}>{props.mainButtonTitle || 'Spara'}</button>
                            <button type="button" className="btn" onClick={() => onCloseModal()}>Avbryt</button>
                        </div>}
                        <Loader />
                    </div>
                </div>
            </div>

        </ReactModal>
    );
}