import Modal from "../Helpers/Modal";
import { useState, useEffect } from "react";
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import Checkbox from "../Helpers/Checkbox";
import { StartGuideObject } from "../../Reducers/startguideReducer";

interface ConfirmStartGuideProps {
    collaborations: StartGuideObject[];
    migrations: StartGuideObject[];
    handleCollaborations: boolean;
}

export default function ConfirmStartGuideDataModal(props: ConfirmStartGuideProps) {
    const migrationAction = useMigrationActionCreator();
    const [isLoading, setIsLoading] = useState(false);

    const [termsCheckbox, setTermsCheckbox] = useState(false);
    const handleCollaborations = props.handleCollaborations;
    const collaborationItems = props.collaborations;

    useEffect(() => {
        if(!handleCollaborations) {
            // Ugly solution to set true if we don't handle collaboration checkbox
            setTermsCheckbox(true);
        }
    },[])

    function onSave() {
        if (isLoading)
            return;

        setIsLoading(true);

        if (handleCollaborations)
            migrationAction.saveCollaborationsData(collaborationItems);
        else
            migrationAction.saveStartGuideDataAo(props.migrations);
    }

    return(
        <><Modal title="Bekräfta" onSave={onSave} checkboxChecked={handleCollaborations ? termsCheckbox : null}>
            {handleCollaborations && <><p>Genom att spara kommer du att skapa <span
                className="font-weight-bold">{collaborationItems.length} st</span> samarbeten</p>
                <p>När du aktiverar samarbeten (med undantag för samarbete i Visma eEkonomi Start) sker följande:<br/><br/>
                    - Din kund kommer att registreras som kund till Visma Spcs AB, om de inte är det sen tidigare.<br/>
                    - Kontaktpersonen som du angett på företaget registreras som användare på vismaspcs.se och får samtidigt tillgång till de tjänster som ingår i samarbetet. Ett mejl med inloggningsuppgifter skickas till kontakten.<br/>
                    - De medarbetare på byrån som jobbar med uppdraget får tillgång till kundens konto på vismaspcs.se och de tjänster som ingår i samarbetet.<br/>
                    - Visma Spcs kan komma att skicka programrelaterad information till din kund.<br/>
                </p>
                <Checkbox onChange={() => setTermsCheckbox(!termsCheckbox)} value={termsCheckbox} label="Jag förstår" />
            </>
            }
            {!handleCollaborations && <p> Genom att spara kommer du att påbörja flytten av data till eEkonomi för <span className="font-weight-bold">{props.migrations.length} st</span> klienter</p>}
        </Modal></>
    );
}