import { useEffect, useState } from "react";
import EAccountingData from "./EAccountingData";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import { startGuideDescriptions } from "../../Constants/startGuideDescriptions";
import CollaborationsGuide from "./CollaborationsGuide";
import { setPageNumber, setSelectedClients, StartGuideObject } from "../../Reducers/startguideReducer";
import { onToggleModal } from "../../Reducers/modalReducer";
import { ErrorList } from "../../Actions/MigrationActionCreator";
import * as formValidationRules from "../../Constants/formValidationRules";

export default function StartGuide() {
    const description = "Gör ett val här för att ändra alla i listan"
    const [lastPage] = useState(4);
    const migrationActions = useMigrationActionCreator();

    const dispatch = useAppDispatch();

    const currentPage = useAppSelector(state => state.startGuide.currentPage);
    const collaborationItems = useAppSelector(state => state.startGuide.collaborationItems);
    const loading = useAppSelector(state => state.startGuide.loading);
    const [hasAoSimplicity, setHasAoSimplicity] = useState(false);
    const aoCustomers = useAppSelector(state => state.users.aoCustomers);
    const [validationErrors, setValidationErrors] = useState<ErrorList[] | null>([]);

    useEffect(() => {
        migrationActions.getMigrationsForBusiness();
    }, [])

    useEffect(() => {
        return () => {
            dispatch(setSelectedClients([]))
        };
    }, []);

    useEffect(() => {
        if (aoCustomers && aoCustomers.length) {

            // can only be one aoCustomer per logged in user
            const currentAo = aoCustomers[0];
            setHasAoSimplicity(currentAo.hasAOSimplicity)
        }
    }, [aoCustomers])

    function fieldHasData(field: string, migration: StartGuideObject) {

        type ObjectKey = keyof typeof migration.eaConfiguration.collaborationOptions;
        var value = migration.eaConfiguration.collaborationOptions[field as ObjectKey];

        return !value;
    }

    const setCurrentPage = (page: number) => {
        
        if (page < currentPage)
            dispatch(setPageNumber(page));
        else if(validateStep()){
            dispatch(setPageNumber(page));
            setValidationErrors([])
        }
    }

    function checkStepperClass(page: number, fields: string[]) {

        if (page === currentPage)
            return "active";

        return "";

        // TODO det här måste fixas
        // if (startGuideState && startGuideState.length > 0) {
        //     if(handleCollaborations) {
        //         if (!startGuideState.some(migration => fields.some(field => fieldHasData(field, migration))))
        //             return "passed"
        //     }
        //     else {
        //         if(page < 3)
        //             return "passed";
        //         if (!startGuideState.some(migration => fields.some(field => migration.eaConfiguration.company[field] === "" || migration.eaConfiguration.company[field] === undefined ||
        //             (migration.eaConfiguration.company[field] instanceof Array && migration.eaConfiguration.company[field].length <= 0))))
        //             return "passed"
        //     }
        // }


    }

    function switchHeader() {
        switch (currentPage) {
            case 1:
                return (<div>
                    <h2 className="font-weight-bold text-white ">{startGuideDescriptions.productInformation.header}</h2>
                    <p className="text-white">{startGuideDescriptions.productInformation.text}</p>
                </div>);
            case 2:
                return (<div>
                    <h2 className="font-weight-bold text-white ">{startGuideDescriptions.invoiceInformation.header}</h2>
                    <p className="text-white">{startGuideDescriptions.invoiceInformation.text}</p>
                </div>);
            case 3:
                return (<div>
                    <h2 className="font-weight-bold text-white ">{startGuideDescriptions.accountingInformation.header}</h2>
                    <p className="text-white">{startGuideDescriptions.accountingInformation.text}</p>
                </div>);
            case 4:
                return (<div>
                    <h2 className="font-weight-bold text-white ">{startGuideDescriptions.bankInformation.header}</h2>
                    <p className="text-white">{startGuideDescriptions.bankInformation.text}</p>
                </div>);

        }
    }

    function onSaveClick() {
        const validationErrors = migrationActions.validateCollaborations(collaborationItems, formValidationRules.collaborationsForm);
        if (validationErrors) {
            setValidationErrors(validationErrors);
        }else{
            dispatch(onToggleModal("confirmSaveStartGuide"));
        }
    }

    const validateStep = (): boolean => {
        let validationErrors;

        switch (currentPage) {
            case 1:
                validationErrors = migrationActions.validateCollaborations(collaborationItems, formValidationRules.collaborationsProductInformation);
                if(validationErrors){
                    setValidationErrors(validationErrors);
                    break;
                }
                return true
            case 2:
                validationErrors = migrationActions.validateCollaborations(collaborationItems, formValidationRules.collaborationsInvoicingInformation);
                if(validationErrors){
                    setValidationErrors(validationErrors);
                    break;
                }
                return true
            case 3:
                validationErrors = migrationActions.validateStartGuideData(collaborationItems, formValidationRules.eAccountingBookeepingInformation);
                if(validationErrors){
                    setValidationErrors(validationErrors);
                    break;
                }
                return true
            case 4:
                validationErrors = migrationActions.validateStartGuideData(collaborationItems, formValidationRules.eAccountingPaymentInformation);
                if(validationErrors){
                    setValidationErrors(validationErrors);
                    break;
                }
                return true
            default:
                return false;
        }
        return false;
    }

    if (loading)
        return null;

    return (
        <form className="form-horizontal">
            <div className="d-flex gap-big">
                <span className="full-width">
                    <h2 className="font-weight-bold text-nowrap">1. Bjud in till samarbete</h2>
                    <div className="stepper">
                        <ul>
                            <li className={checkStepperClass(1, ["eaccountingVariant", "consultantEmail"])}>
                                <a href="#">
                                    <span>Produktinformation</span>
                                    <span className="step">Step 1/4</span>
                                </a>
                            </li>
                            <li className={checkStepperClass(2, ["chargeToCustomer", "period"])}>
                                <a href="#">
                                    <span>Faktureringsinformation</span>
                                    <span className="step">Step 2/4</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </span>
                <span className="full-width">
                    <h2 className="font-weight-bold text-nowrap">2. Uppgifter till Visma eEkonomi</h2>
                    <div className="stepper">
                        <ul>
                            <li className={checkStepperClass(3, ["bookKeepingAccount", "bookKeepingMethod"])}>
                                <a href="#">
                                    <span>Bokföringsinformation</span>
                                    <span className="step">Step 3/4</span>
                                </a>
                            </li>
                            <li className={checkStepperClass(4, ["bank", "vatPeriod"])}>
                                <a href="#">
                                    <span>Betalningsinformation</span>
                                    <span className="step">Step 4/4</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </span>
            </div>
            <section className="panel rounded-10 bg-secondary shadow-50 container-padding">
                {switchHeader()}
            </section>
            <div className="panel py-8">
                {currentPage <= 2 && <CollaborationsGuide onSaveClick={() => onSaveClick} currentPage={currentPage} description={description} collaborations={collaborationItems} hasAoSimplicity={hasAoSimplicity} errors={validationErrors} />}
                {currentPage > 2 && <EAccountingData currentPage={currentPage} description={description} />}
                <div className="d-flex justify-content-between align-items-center">
                    <div style={{ flexGrow: "1", flexBasis: "0" }}>
                        <span className="ml-32 float-left">
                            {currentPage !== 1 && <button type="button" className="btn left"
                                onClick={() => setCurrentPage(currentPage - 1)}>Föregående</button>}
                        </span>
                    </div>
                    <div style={{ flexGrow: "1", flexBasis: "0" }}>
                        <span className="mr-32 float-right">
                            {currentPage !== lastPage && <button type="button" className="btn right"
                                onClick={() => setCurrentPage(currentPage + 1)}>Nästa</button>}
                        </span>
                    </div>
                </div>
            </div>
        </form>
    );
}