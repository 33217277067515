import React from "react";
import { setValue } from "../../Reducers/dropdownReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setCompanyValue, StartGuideObject} from "../../Reducers/startguideReducer";
import { EaccountingBookkeepingSettings } from "./EaccountingBookkeepingSettings";
import { EaccountingBankAndVat } from "./EaccountingBankAndVat";

export interface EAccountingDataProps {
    changeAllDropdownValues: Function;
    description: string;
    migrations: StartGuideObject[];
}

interface Props {
    currentPage: number;
    description: string;
}

export default function EAccountingData(props: Props) {
    const dispatch = useAppDispatch();
    const migrations = useAppSelector(state => state.startGuide.migrations);

    function changeAllDropdownValues(value: any, field: string) {
        dispatch(setValue({ id: field, value }));
        migrations.forEach((migration: StartGuideObject) => {
            dispatch(setCompanyValue({ id: migration.id, field, value }));
        })
    }

    if (!migrations)
        return null;

    return <>
        {props.currentPage === 3 && <EaccountingBookkeepingSettings changeAllDropdownValues={changeAllDropdownValues} description={props.description} migrations={migrations} />}
        {props.currentPage === 4 && <EaccountingBankAndVat changeAllDropdownValues={changeAllDropdownValues} description={props.description} migrations={migrations} />}
    </>
}