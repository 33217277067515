import DropDown from "../Helpers/Dropdown";
import { startGuideDropdowns } from "../../Constants/dropdownLists";
import { BookKeepingMethod } from "../Enums/CommonEnums";
import { setCompanyValue } from "../../Reducers/startguideReducer";
import React from "react";
import { useAppDispatch, useAppSelector} from "../../hooks";
import { EAccountingDataProps } from "./EAccountingData";

export function EaccountingBookkeepingSettings(props: EAccountingDataProps) {

    const dispatch = useAppDispatch();
    const dropdownState = useAppSelector(state => state.dropdown);

    return <>
        <div className="panel-body no-top-bottom-padding">
            <div className="row display-flex align-items-center">
                        <span className="col-2">
                            <div>
                                <p className="font-weight-bold">{props.description}</p>
                            </div>
                        </span>
                <div className="action col-5">
                    <DropDown list={startGuideDropdowns.bookingAccount} label={"Bokföringskonto"}
                              alignment={"align-items-end"}
                              value={dropdownState.inputs["bookingAccount"]}
                              nameValued={true}
                              onChange={(value: any) => props.changeAllDropdownValues(value, "bookKeepingAccount", "company")}
                              blankOption={true} elementWidth={8}/>
                </div>
                <div className="action col-5">
                    <DropDown list={startGuideDropdowns.bookKeepingMethod} label={"Bokföringsmetod"}
                              alignment={"align-items-end"}
                              value={dropdownState.inputs["bookKeepingMethod"]}
                              onChange={(value: BookKeepingMethod) => props.changeAllDropdownValues(Number(value), "bookKeepingMethod", "company")}
                              blankOption={true} elementWidth={8}/>
                </div>
            </div>
        </div>
        <hr className="first-line"></hr>
        {props.migrations.map((migration: any, index: number) => {
            return <span key={index}>
                        {index !== 0 && <hr className="mx-24"></hr>}
                <div className="panel-body no-top-bottom-padding">
                            <div className="row display-flex align-items-center">
                                <span className="col-2">
                                    <div>
                                        <p className="font-weight-bold">{migration.customerName}</p>
                                        <p className="font-weight-bold">{migration.organizationNumber}</p>
                                    </div>
                                </span>
                                <div className="action col-5">
                                    <DropDown list={startGuideDropdowns.bookingAccount} label={"Bokföringskonto *"}
                                              alignment={"align-items-end"}
                                              value={migration.eaConfiguration.company?.bookKeepingAccount}
                                              nameValued={true}
                                              onChange={(value: any) => dispatch(setCompanyValue({
                                                  id: migration.id,
                                                  field: "bookKeepingAccount",
                                                  value
                                              }))}
                                              blankOption={true} elementWidth={8}/>
                                </div>
                                <div className="action col-5">
                                    <DropDown list={startGuideDropdowns.bookKeepingMethod} label={"Bokföringsmetod *"}
                                              alignment={"align-items-end"}
                                              value={migration.eaConfiguration.company?.bookKeepingMethod}
                                              onChange={(value: BookKeepingMethod) => dispatch(setCompanyValue({
                                                  id: migration.id,
                                                  field: "bookKeepingMethod",
                                                  value: Number(value)
                                              }))}
                                              blankOption={true} elementWidth={8}/>
                                </div>
                            </div>
                        </div>
                    </span>
        })}</>
}